@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

/* General Styles */
.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Nunito', sans-serif;
  overflow-x: hidden;
}

h1 {
  text-align: center;
  font-weight: 700;
}

/* Table Selector Styles */
.table-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.table-selector button {
  margin: 5px;
  padding: 10px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
}

.table-selector button:hover,
.table-selector button.active {
  background-color: #2c6c2f;
}

/* About Section */

.about-toggle {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.about-content {
  line-height: 1.6;
}

.about-content h3 {
  margin-top: 15px;
  margin-bottom: 10px;
}

.about-content ul {
  padding-left: 20px;
}

.about-content li {
  margin-bottom: 10px;
}

/* Mobile-specific styles */
.table-selector.mobile {
  display: flex;
  justify-content: space-around;
}

.table-selector.mobile button {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
}

.table-selector.mobile button span {
  font-size: 1.5em;
  margin-bottom: 2px;
}

.table-selector.mobile button small {
  font-size: 0.6em;
  white-space: nowrap;
}

/* Filter Bar Styles */
.filter-bar {
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}

.filter-bar input {
  width: 86%;
  max-width: 300px;
  padding: 10px;
  font-size: 16px;
  padding-right: 30px;
  font-family: 'Nunito', sans-serif;
}

.clear-filter {
  position: absolute;
  right: calc(50% - 145px);
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #888;
}

/* Table Styles */
.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

th {
  background-color: #4CAF50;
  color: white;
  font-weight: 600;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Card Styles */
.cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  background-color: #f9f9f9;
}

.card-heading {
  font-size: 1.2em;
  color: #4CAF50;
  margin-top: 0;
  margin-bottom: 15px;
  border-bottom: 2px solid #4CAF50;
  padding-bottom: 5px;
  font-weight: 700;
}

.card-item {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.card-item strong {
  font-weight: 600;
  color: #4CAF50;
  margin-bottom: 5px;
}

/* GI/GL Value Styles */
.gi-gl-value {
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.low-gi, .low-gl {
  background-color: #7BC043;
  color: #FFFFFF;
}

.medium-gi, .medium-gl {
  background-color: #FFC300;
  color: #000000;
}

.high-gi, .high-gl {
  background-color: #FF5733;
  color: #FFFFFF;
}

/* GIGL Styles */
/* This section is disabled as the corresponding react component is also disabled*/
/* .gigl-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
} */

/* .gigl-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
} */

/* .food-item {
  font-weight: 600;
  flex: 1;
  margin-right: 10px;
} */

/* .gi-gl-values {
  display: flex;
  gap: 10px;
} */

/* Legend Styles */
.legend {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.legend-item {
  display: inline-block;
  margin-right: 20px;
}

.legend-color {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
  border-radius: 2px;
}

/* Notes and Sources Styles */
.notes, .sources {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
}

.sources ul {
  padding-left: 20px;
}

.food-sources-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.food-sources-list li {
  margin-bottom: 8px;
  padding-left: 20px;
  position: relative;
}

.food-sources-list li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #4CAF50;
}

.food-sources-list li:last-child {
  margin-bottom: 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .table-selector:not(.mobile) {
    display: none;
  }

  .card-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .card-item .gi-gl-value {
    margin-left: 0;
    margin-top: 5px;
    width: 100%;
  }

  .clear-filter {
    right: 10px;
  }

  .table-container {
    overflow-x: visible;
  }

  th, td {
    padding: 8px;
  }
}

/* Media query for desktop devices */
@media (min-width: 769px) {
  .table-selector.mobile {
    display: none;
  }

  .food-sources-list {
    columns: 2;
    column-gap: 20px;
  }

  .food-sources-list li {
    break-inside: avoid-column;
  }
}

/* Accessibility improvements */
@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

/* Print styles */
@media print {
  .table-selector,
  .filter-bar,
  .clear-filter {
    display: none;
  }

  .App {
    max-width: 100%;
  }

  table {
    page-break-inside: auto;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }
}